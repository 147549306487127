import axios from 'axios';

class SendSearchStatistic {
    constructor() {
        this.urlSearch = '/service/queries/search';
        this.urlClick = '/service/queries/click';
        this.delay = 3000;
    }

    isSearchActive = () => localStorage.getItem('search') === 'active';

    isHaveId = () => localStorage.getItem('searchID') !== null;

    clearStorage = item => localStorage.removeItem(item);

    sendStat = (url, click) => {
        if (!this.isSearchActive()) {
            return;
        }

        this.clearStorage('search');
        const query = document.querySelector('.form-block__input_search').value;
        const resultCount = document.getElementById('gamesCount').textContent;
        const sendingData = { query, resultCount };

        axios.post(this.urlSearch, sendingData)
            .then(response => response.data)
            .then(({data: id}) => {
                localStorage.setItem('searchID', id);
                if (click) this.sendStatClick(id, url);
            })
            .catch((error) => {
                console.log(error);
                if (url) window.location = url;
            });
    };

    sendStatClick = (id, url) => { 
        id = parseInt(id, 10);
        axios.post(this.urlClick, { id } );
        window.location = url;
    };

    handleGameClick = event => {
        event.preventDefault();
        const url = event.target.href;

        if (this.isHaveId()) {
            const id = localStorage.getItem('searchID');
            this.sendStatClick(id, url);
        } else {
            this.sendStat(url, true);
        }
    }
}

export default SendSearchStatistic;

export default function toggleTabsForGameHall() {
    // Navigation search bar
    $(".main-nav__li_search-bar").click(function () {
        $(".form-block__input_search").focus();
        $(this).siblings().removeClass("active");
    });

    $('.form-block__input_search').focus(() => {
        $('.main-nav__li_search-bar').addClass('main-nav__li_active');
    });

    $('.form-block__input_search').blur(() => {
        $('.main-nav__li_search-bar').removeClass('main-nav__li_active');
    });

    // Toggle game-hall providers nav
    $(".main-nav__li a").click(function () {
        if ($(this).attr("href") === "#SLOTS") {
            $("#game-nav").slideDown();
            $(".game-nav__item").removeClass("active");
            $(".game-nav__item:first-child a").click();
        } else {
            $("#game-nav").slideUp();
        }
    });

    $(".main-nav .tab-item, .game-nav__list .tab-item").click(() => {
        if ($(".search-results").is(":visible")) {
            $(".search-results").hide();
            $(".game-hall .tab-content").fadeIn(400);
        }
    });
}

import 'jquery-countdown';
import { get } from 'axios';
import { compose, replace, toUpper } from 'ramda';

import { BANK_START_SUPER, BANK_ROUND_END } from '../../../../js/constants';
import { simpleHandleError, isMSIE } from '../../../../js/utils';


function getDateString(date) {
    const displayOptions = {
        timeZone: 'Europe/Moscow',
        weekday: 'long',
        day: 'numeric',
        month: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    const getDate = (options) => (date) => new Date(Number(date)).toLocaleString('ru', options);
    const capitalize = replace(/^./, toUpper);
    const addString = compose(
        (string) => `${string} МСК`
    );
    const formatDate = compose(addString, capitalize, getDate(displayOptions));

    return formatDate(date);
}


function renderDateString(counter) {
    const dateContainer = counter.parentNode.querySelector('.date-string');

    if (isMSIE() && !window.EventSource) {
        return;
    }

    if (dateContainer) {
        dateContainer.textContent = getDateString(counter.dataset.time);
    }
}


function eventCounter(counter) {
    const date = counter.dataset.time;
    const type = counter.dataset.type;
    const isRound = type === 'round';
    const isSupergame = type === 'sg';
    const toEnd = counter.dataset.end === 'to-end';

    const TIMEOUT_GET = 1500;   // если счетчик на клиенте уйдет вперед
    const ROUND_TIME = 3600000; // длительность раунда 1 час

    let loopCount = 1;


    function render(event) {
        counter.querySelector('.event-counter__digits').textContent = event.strftime('%D : %H : %M : %S');
    }

    function clearCounterPanel() {
        const container = document.querySelector('#tour-step1 .tour__text');
        container.removeAttribute('id');
        container.classList.add('tour__text_single');
        container.textContent = 'Совсем скоро будет очередная Суперигра';

        document.querySelector('.date-string').remove();
        counter.remove();
    }

    function refresh(newDate) {
        document.getElementById('tour-text-start').textContent = 'Осталось до конца Суперигры';
        document.getElementById('tour-text-start').setAttribute('id', 'tour-text-end');

        $(counter).countdown(newDate);
    }

    async function getNewData() {
        try {
            const { data } = await get(BANK_START_SUPER);

            if (data < 1) {
                const newDate = await get(BANK_ROUND_END);
                refresh(newDate.data);
            }
            else {
                clearCounterPanel();
            }
        }
        catch(error) {
            simpleHandleError(error);
        }
    }

    function handleFinish() {
        $(counter).countdown('remove');

        if (!isRound && !isSupergame) {
            return;
        }

        if (isRound) {
            const newRoundDate = Number(date) + (ROUND_TIME * loopCount);
            loopCount = loopCount + 1;
            $(counter).countdown(newRoundDate);
        }

        if (isSupergame) {
            if (toEnd) {
                setTimeout(() => getNewData(), TIMEOUT_GET);
            }
            else {
                clearCounterPanel();
            }
        }
    }

    function init(startDate) {
        $(counter)
            .countdown(startDate, render)
            .on('finish.countdown', handleFinish)
    }

    init(date);
}

export default function showEventCounter() {
    const counters = [...document.querySelectorAll('[id^=event-counter_]')];
    counters.map((counter) => {
        eventCounter(counter);
        renderDateString(counter);
    });
}

import { tns } from "tiny-slider/src/tiny-slider";

export const gameNavSlider = () => {
    const slider = document.querySelector("#game-nav-container");

    if (slider) {
        tns({
            container: slider,
            loop: false,
            items: 10,
            slideBy: "page",
            nav: false,
            prevButton: ".js-game-nav-prev",
            nextButton: ".js-game-nav-next",
            speed: 200,
        });
    }
};

export default function initMoveBackground() {
    const element = document.getElementById('tour-step1');
    if (!element) {
        return;
    }
    function handleMove(event){
        const elementLeft = element.offsetLeft;
        const elementWidth = element.offsetWidth;
        const circleWidth = 500;
        const minPosition = -circleWidth;
        const maxPosition = elementWidth - circleWidth;
        let mouseX = 0;

        mouseX = event.clientX - elementLeft - circleWidth;

        if (mouseX > minPosition && mouseX < maxPosition) {
            element.style.backgroundPosition = `${mouseX}px 0,0 0`;
        }
    }
    document.captureEvents(Event.MOUSEMOVE);
    document.addEventListener("mousemove", handleMove, false);
}

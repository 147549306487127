import { simpleHandleError, getResources } from '../../../../js/utils';
import { get } from 'axios';
import { URL_WARNING } from '../../../../js/constants';
import { getSettings, setSettings } from './notification-settings';

const defaultNoticeSettings = {
  warning: {show: true, showDate: null},
  docs: {show: true, showDate: null},
};

const noticeSettings = getSettings('notice-settings', defaultNoticeSettings);

function showNoticePanel(panel) {
  let header = document.getElementsByClassName('header-wrapp');
  if (header.length) {
    $(panel).insertAfter(header[0]);
  }
  return;
}

function renderNoticePanel(text, callback){
  const message = document.createElement('div');
  message.className = 'warning-popup warning-popup_favourite';
  message.innerHTML = `
  <div class="container-alert">
    <div class="container-alert__text-warning">${text}</div>
    <i class="fa fa-cancel"></i>
  </div>
  `;
  const closeBtn = message.querySelector('.fa-cancel');
  closeBtn.addEventListener('click', () => {
      message.remove();
      if (callback){
        callback();
      }
  });

  showNoticePanel(message);
  return message;
}

async function getNotification() {
  try {
      const { data } = await get(URL_WARNING);

      if (data.error) {
          document.body.dispatchEvent(new Event('no-notice'));
          return;
      }

      const { message, updateDate } = data.data;
      const { warning } = noticeSettings;
      const savedDate = parseInt(warning.showDate, 10);

      if (!warning.show && updateDate === savedDate) {
          document.body.dispatchEvent(new Event('no-notice'));
          return;
      }

      renderNoticePanel(message, () => {
          let updatedNoticeSettings = getSettings('notice-settings'); //noticeSettings not update globally
          updatedNoticeSettings.warning.show = false;
          localStorage.setItem('showNotice', 'false')
          setSettings('notice-settings', updatedNoticeSettings);
          document.body.dispatchEvent(new Event('notice-closed'));
      });
      noticeSettings.warning.show = true;
      noticeSettings.warning.showDate = updateDate;
      setSettings('notice-settings', noticeSettings);

      return true;
  }
  catch (e) {
      simpleHandleError(e);
  }
}

function getWindowNotification(init = false){
  const documentCheck = window.docsInfo;
  const { docs } = noticeSettings;
  const savedDate = docs.showDate ? docs.showDate : '';

  function renderWindowNotification({ verificationStatus, comment }) {
      if (verificationStatus === 'EXPECTS'){
          renderNoticePanel(comment, () => {
              const updatedNoticeSettings = getSettings('notice-settings'); //noticeSettings not update globally
              updatedNoticeSettings.docs.show = false;
              updatedNoticeSettings.docs.showDate = documentCheck.updateDate || null;
              setSettings('notice-settings', updatedNoticeSettings);
          });
      }
  }

  if (!documentCheck || !documentCheck.comment || (!docs.show && savedDate === documentCheck.updateDate)){
    return;
  }

  if (init) {
    renderWindowNotification(documentCheck)
    return
  }

  document.body.addEventListener('no-notice', function () {
      renderWindowNotification(documentCheck);
  });

  document.body.addEventListener('notice-closed', function () {
      renderWindowNotification(documentCheck);
  });
}

function handleDocumentNotification() {
  const hash = document.location.hash;
  const navTabs = document.querySelector('.nav-tabs');
  const documentNotification = document.querySelector('.warning-popup_documents');
  const closeBtn = documentNotification.querySelector('.fa-cancel');

  if (hash === "#verification") {
    documentNotification.classList.remove("hidden")
  }

  if (navTabs) {
    navTabs.addEventListener('click', (event) => {
      if (event.target.hash === "#verification") {
        documentNotification.classList.remove("hidden")
      } else {
        documentNotification.classList.add("hidden")
      }
    });
  }

  closeBtn.addEventListener('click', () => {
    documentNotification.remove();
  });
}

async function loadDocumentNotification() {
  const documentNotification = document.querySelector('.warning-popup_documents');

  try {
    const resources = await getResources('profileVerification');
    if (resources.data) {
      handleDocumentNotification();
    } else {
      documentNotification && documentNotification.remove();
    }
  } catch (err) {
    console.log(err);
  }
}

export { renderNoticePanel, getNotification, getWindowNotification, loadDocumentNotification };

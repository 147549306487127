import axios from 'axios';

export function gameFavoriteClickHandler(event) {
    if (event.target.classList.contains('fa')) {
        event.preventDefault();
        const game = this;
        const $game = $(this);

        const sendingData = {};
        sendingData.code = game.dataset.code;
        sendingData.provider = game.dataset.prov;

        const isFavorite = this.dataset.infavorite;
        if (isFavorite === 'true') {
            axios.post('/service/player/game/remove', sendingData)
                .then(response => response.data)
                .then(res => res.data ? removeGame(game) : handleError(res.error.items[0] || res.error.items))
                .catch(error => handleError(error));
        } else {
            axios.post('/service/player/game/add', sendingData)
                .then(response => response.data)
                .then(res => res.data ? addToFavourites(game, $game) : handleError(res.error.items[0] || res.error.items))
                .catch(error => handleError(error));
        }
    }

    /**
     * Cloning chosen game to hall "My games"
     * @param game
     * @param $game
     */
    function addToFavourites(game, $game) {
        // change game in hall
        game.setAttribute('data-infavorite', 'true');
        game.querySelector('.fa-star-empty').classList.add('fa-star-empty_active');
        var gamePage = $('#game-container').length;

        // clone game to favorite
        $('#my-games .provider-wrapp .favorite-games-help').hide();
        const $clonedGame = $game.clone(true).appendTo('#my-games .provider-wrapp');

        // open info-panel
        const gameName = $game.find('.overlay__header').text();
        let text = `Игра <span class="container-alert__game-name">${gameName}</span> добавлена в избранное`;
        if (!location.pathname.includes('/game/'))
            openFavouriteInfo(game.dataset.code, text, gamePage, 5000);
    }

    function setGameNotFavorite(game) {
        game.setAttribute('data-infavorite', 'false');
        const heart = game.querySelector('.fa-star-empty');
        if (heart !== null) {
            heart.classList.remove('fa-star-empty_active');
        }
    }

    function removeGame(game) {
        // delete game from favorite

        const gameContainer = document.querySelector('#my-games .provider-wrapp');
        if (gameContainer) {
            const clonedGame = gameContainer.querySelector(`[data-code=${game.dataset.code}]`);
            gameContainer.removeChild(clonedGame);
            const favoriteGames = gameContainer.querySelector('div');
            if (favoriteGames === null || favoriteGames.length === 0) {
                $('#my-games .provider-wrapp .favorite-games-help').show();
            }
        }

        // change game in hall
        const gameOriginContainer = document.querySelector('.game-hall');
        if (gameOriginContainer){
            const originGame = gameOriginContainer.querySelector(`[data-code=${game.dataset.code}]`);
            if (originGame !== null) {
                setGameNotFavorite(originGame);
            }
        }

        setGameNotFavorite(game);
    }

    function handleError(error) {}
}

/**
 *
 * @param code {string} - game code
 * @param name {string} - game name
 * @param gamePage {boolean} - on which page this will be rendered
 */
export function openFavouriteInfo(code, text, gamePage, interval) {
    const message = document.createElement('div');
    message.id = code;
    if (gamePage >= 0) {
        message.className = gamePage ? 'download-alert download-alert_favourite download-alert_game' : 'download-alert download-alert_favourite';
        message.innerHTML = `
        <div class="container-alert">
          <div class="container-alert__text">${text}</div>
          <i class="fa fa-cancel"></i>
        </div>
        `;
        document.body.appendChild(message);
    } else if (gamePage === 'warning') {
        message.className = 'warning-popup warning-popup_favourite';
        message.innerHTML = `
        <div class="container-alert">
          <div class="container-alert__text-warning">${text}</div>
          <i class="fa fa-cancel"></i>
        </div>
        `;
        let header = document.getElementsByClassName('header-wrapp');
        if (header.length) {
            $(message).insertAfter(header[0]);
        }
    }

    const closeBtn = message.querySelector('.fa-cancel');
    closeBtn.addEventListener('click', () => {
        localStorage.setItem('showAlertBonus', false);
        message.remove();
    });

    if (interval !== 0) {
        setInterval(() => {
            const messageNode = document.getElementById(code);
            if (messageNode) {
                messageNode.remove();
            }
        }, interval);
    }
}

/**
 * Get player's favorite games
 *
 * @returns {Array} result
 */
export function getFavoriteGamesArray(favList) {
    if (window.userLogged) {
        const favoriteGames = document.querySelectorAll('#my-games .provider-wrapp .game');
        const result = [];
        if ($('#my-games').length)
            for (let favoriteGame of favoriteGames) {
                result[favoriteGame.dataset.code] = true;
            }
        else{
            for (let favoriteGame of favList) {
                if (favoriteGame.favorite)
                    result[favoriteGame.code] = true;
            }
        }
        return result;
    }
    else
        return false;
}

/**
 * Get player's favorite games from API
 *
 * @returns {Promise} result
 */
export function fetchFavoriteGameList() {
    return axios.get('/service/player');
}

export function detectFavouriteGames() {
    const favouriteArray = fetchFavoriteGameList();
    favouriteArray.then((res) => {
        if (res.data.favoriteGames) {
            for (const favouriteGame of res.data.favoriteGames) {
                const game = document.querySelector(`.game[data-code=${favouriteGame.code}]`);
                const heart = document.querySelector(`.game[data-code=${favouriteGame.code}] .fa-star-empty`);
                if (heart) {
                    heart.classList.add('fa-star-empty_active');
                }
                if (game) {
                    game.dataset.infavorite = true;
                }
            }
        }
    });
}

export function handleFavouriteGameClick() {
    const gamesList = [...document.querySelectorAll('.game')];
    gamesList.forEach((game) => {
        game.addEventListener('click', gameFavoriteClickHandler);
    });
}

import 'slick-carousel';

function mainSliderPlugin() {
    const options = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        draggable: false,
        autoplay: true,
    };

    $('.main-slider').slick(options);
    $('.main-slider').slick('refresh');
}

export { mainSliderPlugin };

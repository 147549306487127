import { get } from 'axios';
import { currencyIcon } from '../../../../blocks/modules/_common/i18n/i18n';
import { URL_WINNERS } from '../../../../js/constants';

const processWinners = {

  getWinners: function(reload = false) {
    this.lastWinner = localStorage.getItem('lastWinner');
    this.newWinnersAvailable = false;

    get(URL_WINNERS)
      .then(response => {
        const { dailyWinners, nextUpdateDate, currentDate } = response.data;
        const nextUpdateTimer = parseInt(nextUpdateDate - currentDate);

        if (dailyWinners.length <= 0) {
          return;
        }

        if (reload) {
          this.clearTimer();
          this.lastWinner = dailyWinners.length;
        }

        this.insertWinnerList(dailyWinners);
        this.checkReloadWinners(nextUpdateTimer); //milliseconds to next data load
      })
      .catch(error => {
        console.log(error);
      });
  },

  checkReloadWinners: function(timer) {
    if (timer <= 0){
      return;
    }
    return setTimeout(() => {
      this.newWinnersAvailable = true;
     }, timer);
  },

  renderWinner: function(winner) {
    const { gameImgUrl, game, gameName, player, sum, shortProvider, gold } = winner;
    const imgPath = gameImgUrl.win != null ? gameImgUrl.win : '/img/games/game_def_win.png';
    const gamePath = `/game/${window.userLogged ? 'real' : 'demo'}/${shortProvider}/${game}`;
    const sumFormatted = parseInt(sum).toLocaleString('ru');
    const goldIcon = '<i class="fa fa-gold"></i>';
    const winnerTemplate = `
    <div class="winner">
      <a href="${gamePath}" class="winner__img">
      <img src="${imgPath}" alt="${gameName}">
      </a>
      <div class="winner__data">
      <span class="winner__name">${player}</span>
      <span>${gold ? '  сорвал банк' : ' выиграл'}</span>
      <br>
      <span class="digits winner__sum">${sumFormatted} ${gold ? goldIcon : currencyIcon()}</span>
      <br>
      <a href="${gamePath}">в ${gameName}</a>
      </div>
    </div>`;
    return winnerTemplate;
  },

  renderWinnerList: function(winners) {
    let list = '';
    winners.forEach((value, key, mapObj) => {
      if (value) {
        list += this.renderWinner(value);
      }
    });
    return list;
  },

  insertWinnerList: function(winners) {
    const winnersSize = winners.length;
    const blockSize = 5; //count of winners in container
    const container = document.querySelector('.winners__list');
    let sliceArray;
    let counter = this.lastWinner && this.lastWinner < winnersSize ? this.lastWinner : winnersSize;
    let winnerBegin;

    const sliceWinners = () => {
      winnerBegin = counter - blockSize;
      sliceArray = winners.slice(winnerBegin, counter);
      counter = winnerBegin > 0 ? counter - 1 : winnersSize;
      localStorage.setItem('lastWinner', counter);
      if (this.newWinnersAvailable && winnerBegin <= 0) { //reload if all list showed and new data Available
        this.getWinners(true);
        return;
      }
      container.innerHTML = this.renderWinnerList(sliceArray);
    }

    sliceWinners();
    this.insertInterval = setInterval(() => { sliceWinners(); }, 6000);
  },

  clearTimer: function() {
    clearInterval(this.insertInterval);
  },
}

export default processWinners;

import toggleTabsForGameHall from '../../blocks/modules/_common/game-hall/game-hall';
import renderHalls from '../../blocks/modules/_common/game-hall/halls';
import initGameSearch from '../../blocks/modules/_common/game-hall/game-hall__search';
import showEventCounter from '../../blocks/modules/_common/event-counter/event-counter';
import processWinners from '../../blocks/modules/_common/winners/winners';
import { mainSliderPlugin } from '../../blocks/modules/index/main-slider/main-slider';
import initMoveBackground from '../../blocks/modules/_common/tour/tour';
import '../../libs/perfect-scrollbar/js/perfect-scrollbar.jquery.min';
import { getUrlParameter, openInfoPopup, getResources, getParamsFromURL, renderPlayerNames } from '../utils';
// import { SubscriberToWebsocket, handleSocketUpdate } from '../lib/sockets'
import { getNotification } from '../../blocks/modules/_common/notification-panel/notification'
import { gameNavSlider } from "../../blocks/modules/_common/game-nav";

function checkParams() {
    const forgot = getUrlParameter('forgotCode');
    $('#forgotCode_input').val(forgot);

    if (forgot && forgot !== '') {
        $.magnificPopup.open({
            items: {
                src: '#change-pass',
            },
            type: 'inline',
            closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
        });
    }

    switch (getUrlParameter('emailVerification')) {
        case '': {
            break;
        }
        case 'ok': {
            openInfoPopup('Успешно', 'Ваш email подтвержден');
            break;
        }
        case 'no': {
            openInfoPopup('Ошибка', 'Подтвердить email не удалось');
            break;
        }
    }

    const subscribeCode = getUrlParameter('confirmationCode')
    if (subscribeCode.length > 0) {
        openInfoPopup('Подписка на рассылку', 'Поздравляем! Вы подписаны на рассылку.')
    }
}


async function loadSocials() {
    try {
        const resources = await getResources('social');
        const isLinux = navigator.userAgent.indexOf('Linux') !== -1;
        var os = '';
        if (isLinux)
            os = 'linux';
        if (resources){
            $('.js-social-links').append(`<p class="social-links__label">Подпишитесь на нас:</p>`);
            var blockedSocials = ['vk', 'odnoklassniki'];
            $.each(resources, function(i, item) {
                if (i.indexOf('.link') >= 0){ //TODO filter json
                    var network = i.split('.');
                    var prefix = network.length > 0 ? network[0] : false;
                    if (!showAllSocial && blockedSocials.includes(prefix)){
                        return;
                    }
                    if (prefix){
                        if (resources[`${prefix}.name`]){
                            //resources[`${prefix}.name`]
                        }
                        $('.js-social-links').append(`<a href="${item}" target="_blank" class="fa fa-${prefix} ${os}"></a>`);
                    }
                }
            });
        }
    } catch (err) {
        console.log(err);
    }
}

function lazyLoadSlider() {
    const images = [...document.querySelectorAll('img[data-src]')];

    images.forEach((img) => {
        img.setAttribute('src', img.getAttribute('data-src'));
        img.onload = function() {
            img.removeAttribute('data-src');
        };
    })

    mainSliderPlugin();
}

function isPerfectScrollbar() {
    $('.scrollbar-container').perfectScrollbar({
        wheelSpeed: 2,
        scrollYMarginOffset: 10,
        maxScrollbarLength: 190,
        suppressScrollX: true
    });
}

isPerfectScrollbar();
checkParams();
showEventCounter();
toggleTabsForGameHall();
renderHalls(8, true);
initGameSearch();
loadSocials();
processWinners.getWinners();
initMoveBackground();
renderPlayerNames();
getNotification();
gameNavSlider();

// const subscriber = new SubscriberToWebsocket(handleSocketUpdate)
// subscriber.init()

document.body.addEventListener('stylesloaded', () => lazyLoadSlider());

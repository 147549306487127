import axios from 'axios';
import { Tabs } from 'bootstrap';

import { simpleHandleError, initCommonPopup } from '../../../../js/utils';
import { gameFavoriteClickHandler, getFavoriteGamesArray } from '../../../../js/favorite';
import { GAME_IMG_DEF } from '../../../../js/constants';


/**
 * Convert attributes from JSON to string
 * @param {{}} obj - Attributes
 * @return {string} - Attributes for markup
 */
function getAllAttributes(obj) {
    let str = '';
    for (const prop in obj) {
        if( obj.hasOwnProperty( prop ) ) {
            str += `${prop}="${obj[prop]}" `
        }
    }
    return str;
}

/**
 * Check attributes presence for game
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {{}} - Attributes in JSON
 */
function checkAttributes(game, imgType) {
    if (game.images[imgType] &&
        game.images[imgType].attributes &&
        !$.isEmptyObject(game.images[imgType].attributes)) {
        return game.images[imgType].attributes;
    }

    return {'alt': game.name};
}

/**
 * Check image of given type exist
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {boolean} - is image of given type exist
 */
function checkImageExist(game, imgType) {
    return (
        !$.isEmptyObject(game.images) &&
        !$.isEmptyObject(game.images[imgType]) &&
        game.images[imgType].link !== ''
    )
}
/**
 *  For each game create template and append it to DOM
 */
function renderGames(list, amount, hall, favoriteGames, $container) {
    let playButton = '';
    let demoUrl = '';

    $.each(list, (index, game) => {
        if (window.userLogged) {
            playButton = `<a class="btn btn_green btn_play" href="/game/real/${game.providerCode}/${game.code}">Играть</a>`;
            demoUrl = 'fun/';
        }
        else {
            playButton = '<a class="btn btn_green btn_play btn_popup" href="#register-popup">Играть</a>';
            demoUrl = 'demo/';
        }
        const attributes = getAllAttributes(checkAttributes(game, 'logo'));
            const imgPath = checkImageExist(game, 'logo')
                ? game.images.logo.link
                : GAME_IMG_DEF;
        const newLabel = game.status.gameIsNew
            ? '<span class="game__status-label game__status-label_new">new</span>'
            : '';
        const prizeData = game.points !== null && game.record !== null
            ? `<div class="game-info">
                <div class="game-info__gold"><i class="fa fa-sorvibank"></i><span class="game-info__label">${parseInt(game.points, 10).toLocaleString('ru')}</span><i class="fa fa-gold"></i></div>
                <div class="game-info__record"><i class="fa fa-winner"></i><span class="game-info__label">${parseInt(game.record, 10).toLocaleString('ru')}</span></div>
            </div>`
            : '';
        const isFavorite = favoriteGames && favoriteGames[game.code] === true;

        /**
         * Create template for game
         */
        const $pattern = $(
            `
        <div class="game" data-code="${game.code}" data-prov="${game.providerCode}" data-infavorite="${isFavorite
                ? 'true'
                : 'false'}">
            <img src="${imgPath}" ${attributes}>
            <div class="game__labels-wrapp">
              ${newLabel}
            </div>
            ${prizeData}
            <div class="overlay overlay_game">${window.userLogged
                ? `<div class="fa fa-star-empty ${isFavorite
                      ? 'fa-star-empty_active'
                      : ''}"></div>`
                : ''}<span class="overlay__header">${game.name}</span>
                ${playButton}
                <a href="/game/${demoUrl + game.providerCode}/${game.code}" class="overlay__demo">Демо</a>
            </div>
        </div>
        `,
        );

        /**
         * Insert template to DOM
         */
        $container.append($pattern);
        $pattern.click(gameFavoriteClickHandler);
    });

    /**
     * Remove listeners
     */
    if (hall){
        const openedHall = document.querySelector(`[data-hall=${hall}]`);
        if (openedHall)
            openedHall.removeEventListener('click', handleClickOnHall);
    }
    initCommonPopup('.btn_play');
}

export function getRandomGameList(){
    return new Promise((resolve, reject) => {
        let playerAmount = 0;

        axios.get(`/service/hall/randomgames?limit=6`).then((response) => {
            const gamesList = response.data;
            //const favoriteGames = getFavoriteGamesArray(gamesList);
            //console.log(favoriteGames);
            $('.provider-wrapp').html('');
            if (window.userLogged) {
                axios.get(`/service/player`).then((response) => {
                    const responsePlayer = response.data;
                    const favoriteGames = getFavoriteGamesArray(responsePlayer.favoriteGames);
                    playerAmount = responsePlayer.playerHeader.realAccount.realAmount;
                    renderGames(gamesList, playerAmount, false, favoriteGames, $('.provider-wrapp'));

                })
                .catch(error => simpleHandleError(error));
            }
            else
              renderGames(gamesList, playerAmount, false, false, $('.provider-wrapp'));
        })
        .catch(error => simpleHandleError(error));
    });
}

async function getGameList(hall, cutLength) {
    try {
        const responseGames = await axios.get(`/service/hall/${hall}/games?limit=${cutLength ? cutLength : ''}`);
        const gamesList = responseGames.data;
        let playerAmount = 0;

        if (window.userLogged) {
            const responsePlayer = await axios.get('/service/player');
            playerAmount = responsePlayer.data.playerHeader.realAccount.realAmount;
        }

        const favoriteGames = getFavoriteGamesArray();
        const $container = $(`#${hall} .provider-wrapp`);

        renderGames(gamesList, playerAmount, hall, favoriteGames, $container);
    } catch (error) {
        simpleHandleError(error);
    }
}


/**
 * Get chosen hall and send the request
 */
function handleClickOnHall(cutLength, link) {
    let hallName = link.dataset.hall;
    if (hallName.toLowerCase() === 'slots') {
        hallName = document.querySelector('#game-nav [data-hall]').dataset.hall;
    }

    const gameContainer = document.querySelector(`#${hallName} .provider-wrapp`);

    if (location.href.includes('/all-games')) {
        history.replaceState(null, null, `/all-games/hall/${hallName}`);
    }

    const isHallRendered = gameContainer.childNodes.length > 0;
    if (isHallRendered) {
        return
    }

    getGameList(hallName, cutLength);

}


export default function renderHalls(cutLength = 0, loadFirst = false) {
    /**
     * Define halls' toggles and set listener for thom
     */
    const hallList = [...document.querySelectorAll('[data-hall]')];

    hallList.forEach(hall => hall.addEventListener('click', () => handleClickOnHall(cutLength, hall)));

    /**
     * Init getting games for first tab
     */
    loadFirst && hallList[0].click();
}

export function checkSlotsActive() {
    const gamesNav = document.querySelector("#game-nav");
    const providerList = [...gamesNav.querySelectorAll(".game-nav__item")];
    const isSlotsActive = providerList.some((element) =>
        element.classList.contains("active")
    );
    if (isSlotsActive) {
        gamesNav.style.display = "block";
    }
}

import axios from 'axios';
import { initCommonPopup, simpleHandleError } from '../../../../js/utils';
import { gameFavoriteClickHandler } from '../../../../js/favorite';
import SendSearchStatistic from '../../../../js/search-stat';
import { GAME_IMG_DEF } from "../../../../js/constants"

/**
 * Convert attributes from JSON to string
 * @param {{}} obj - Attributes
 * @return {string} - Attributes for markup
 */
function getAllAttributes(obj) {
    let str = '';
    for (const prop in obj) {
        if( obj.hasOwnProperty( prop ) ) {
            str += `${prop}="${obj[prop]}" `
        }
    }
    return str;
}

/**
 * Check attributes presence for game
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {{}} - Attributes in JSON
 */
function checkAttributes(game, imgType) {
    if (game.images[imgType] &&
        game.images[imgType].attributes &&
        !$.isEmptyObject(game.images[imgType].attributes)) {
        return game.images[imgType].attributes;
    }

    return {'alt': game.name};
}

/**
 * Check image of given type exist
 * @param {{}} game - Game object
 * @param {string} [imgType = 'logo'] - Type of image
 * @return {boolean} - is image of given type exist
 */
function checkImageExist(game, imgType) {
    return (
        !$.isEmptyObject(game.images) &&
        !$.isEmptyObject(game.images[imgType]) &&
        game.images[imgType].link !== ''
    )
}

export default function initGameSearch() {
    let gamesList = [];
    let favoriteGames = [];

    let playButton = '';
    let demoUrl = '';
    let favIcon = '';

    const $countSpan = $('#gamesCount');
    const $container = $('#search-wrapp');

    async function getGameList() {
        try {
            const responseGame = await axios.get('/service/games/');
            gamesList = responseGame.data;
        } catch (error) {
            simpleHandleError(error);
        }
    }

    function renderGameList(request) {
        if (request.length > 1) {
            request = request.toLowerCase();
            $container.html('');
            let countOfGames = 0;

            gamesList.forEach((game) => {
                if (game.name.toLowerCase().includes(request) || game.tags.toLowerCase().includes(request)) {
                    if (window.userLogged) {
                        playButton = `<a class="btn btn_green btn_play" href="/game/real/${game.providerCode}/${game.code}">Играть</a>`;
                        demoUrl = 'fun/';
                    }
                    else {
                        playButton = '<a class="btn btn_green btn_play btn_popup" href="#register-popup">Играть</a>';
                        demoUrl = 'demo/';
                    }
                    const attributes = getAllAttributes(checkAttributes(game, 'logo'));
                    const imgPath = checkImageExist(game, 'logo')
                        ? game.images.logo.link
                        : GAME_IMG_DEF;
                    const newLabel = game.status.gameIsNew
                        ? '<span class="game__status-label game__status-label_new">new</span>'
                        : '';
                    const prizeData = game.points !== null && game.record !== null
                        ? `<div class="game-info">
                            <div class="game-info__gold"><i class="fa fa-sorvibank"></i><span class="game-info__label">${parseInt(game.points, 10).toLocaleString('ru')}</span><i class="fa fa-gold"></i></div>
                            <div class="game-info__record"><i class="fa fa-winner"></i><span class="game-info__label">${parseInt(game.record, 10).toLocaleString('ru')}</span></div>
                        </div>`
                        : '';

                    if (window.userLogged && game.favorite) {
                        favIcon = '<div class="fa fa-star-empty fa-star-empty_active"></div>'
                    } else if (window.userLogged && !game.favorite) {
                        favIcon = '<div class="fa fa-star-empty"></div>'
                    } else {
                        favIcon = ''
                    }

                    const $pattern = $(`<div class="game" data-code="${game.code}" data-prov="${game.providerCode}" data-infavorite="${game.favorite ? 'true' : 'false'}">
                                       <img src="${imgPath}" ${attributes}>
                                       <div class="game__labels-wrapp">
                                         ${newLabel}
                                       </div>
                                       ${prizeData}
                                       <div class="overlay overlay_game">
                                           ${favIcon}
                                           <span class="overlay__header">${game.name}</span>
                                           ${playButton}
                                           <a href="/game/${demoUrl + game.providerCode}/${game.code}" class="overlay__demo">Демо</a>
                                       </div>
                                    </div>`);

                    $container.append($pattern);
                    $pattern.click(gameFavoriteClickHandler);
                    $pattern.click(gameClickHandler);

                    countOfGames++;
                }
                $countSpan.text(countOfGames);
            });

            $('.game-hall .tab-content').hide();
            $('.search-results').fadeIn(400);
        } else {
            $('.game-hall .tab-content').fadeIn(400);
            $('.search-results').hide();
        }


        initCommonPopup('.btn_play');
    }

    const sendSearch = new SendSearchStatistic();

    function gameClickHandler(event) {
        const isGameLink = event.target.classList.contains('btn_play') || event.target.classList.contains('overlay__demo');
        if (isGameLink) {
            sendSearch.handleGameClick(event);
        }
    }

    function handleSearchInput(input) {
        localStorage.setItem('search', 'active');
        sendSearch.clearStorage('searchID');

        let timeout = null;
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            sendSearch.sendStat(null, false);
            clearTimeout(timeout);
        }, sendSearch.delay);

        renderGameList(input.value)
    }

    const searchField = document.querySelector('.form-block__input_search');
    searchField.addEventListener('focus', () => getGameList());
    searchField.addEventListener('keyup', () => handleSearchInput(searchField));

}


function getSettings(name, defaultConfig){
  let noticeSettings = localStorage.getItem(name);
  if (noticeSettings){
    noticeSettings = JSON.parse(noticeSettings);
    return noticeSettings;
  }
  else{
    localStorage.setItem(name, JSON.stringify(defaultConfig));
    return defaultConfig;
  }
}

function setSettings(name, config) {
  config = JSON.stringify(config);
  localStorage.setItem(name, config);
}

export { getSettings, setSettings };